import { CleanUpdateFields, CleanUpdateFieldsGlobalGrid, postItems } from "../../actions";
import { setCellColorBackgroundFromValue } from "./customCellStyle";
import { calculatePoCost } from "../../common/formulas";
import { initColumnSum } from "../SumByColumn/SumByColumn";

export const handleNodesInitialized = (e) => {
 
  function sumForEachColumn(node) {
    Object.keys(sumByColumn).forEach( key => sumByColumn[key]+= +node.data[key] );
  }

  function displayNodeLocal(node, recursionIndex) {
    node.visible = true;
    if (recursionIndex <= 5) {
      if (node.children.length > 0) {
        node.hasChildren = true;
        node.children.forEach((node) => {
          displayNodeLocal(node, recursionIndex + 1);
          sumForEachColumn(node);
        });
      }
    }
  }

  const visibleNodes = e.root.children.filter((x) => x.visible === true);
  const sumByColumn = initColumnSum(0);
  visibleNodes.forEach(function (node) {
    displayNodeLocal(node, 1);
    sumForEachColumn(node);
  });
  return sumByColumn;
};

const isEditableCellInCategoryList = (e, categoriesList) => {
  return e.column.allowEditing && categoriesList[e.data.programId]?.find(x => x.categoryId === e.data.categoryId);
};


export const onCellPrepared = (e, settingsData, categoriesList) => {
  if (e.rowType === "data") {
    const programId = e.data["programId"];
    const categoryId = e.data["categoryId"];
    const categorySettings = settingsData.filter((x) => x.idCategory === categoryId && x.idProgram === programId);
    if (categorySettings) {

      const handleSetting = (greaterThanCode, limitCode, columnName) => {
        const greaterThan = categorySettings["codeLetter_" + greaterThanCode];
        const limit = categorySettings["codeLetter_" + limitCode];

        if (greaterThan && greaterThan.value === "F" && limit) {
          setCellColorBackgroundFromValue(e, columnName, limit.value);
        } else if (e.rowType === "data") {
          setCellColorBackgroundFromValue(e, columnName, e.data["L12M"]);
        }
      };

      handleSetting("D", "I", "BO_total");
      handleSetting("E", "F", "CommitTotal");
    }
    if (isEditableCellInCategoryList(e, categoriesList)) {
      e.cellElement.style.backgroundColor = "#d6ecff8f";
    }
  }
}

export const onContextMenuPreparing = (e, action) => {
  if (e.rowType === "data") {
    e.items = [
      {
        text: "View Editable Fields Log",
        onItemClick: function () {
          return action(e);
        },
      },
    ];
  }
};

async function sendData(value, currentRowData, dbFieldName, type, userId) {
  const session_id = window.sessionStorage.getItem("session_id"); 
  const payload = {
    sessionId: session_id,
    Id: currentRowData.Id,
    keyItem: currentRowData.keyItem,
    InternalId: currentRowData.InternalId,
    programId: currentRowData.programId,
    categoryId: currentRowData.categoryId,
    fieldName: dbFieldName,
    fieldValue: String(value),
    type: type,
    userId: userId,
  };
  await postItems(payload);
}

export async function setCellValue( newData, value, currentRowData, field, dbFieldName, type, userId ) {
  if (value === null) {
    value = 0;
  }
  newData[field] = value;
  await sendData(value, currentRowData, dbFieldName, type, userId);
}

export function setCellValueAndCost( newData, value, currentRowData, field, dbFieldName, type, userId, costFieldName ) {
  if (value === null) {
    value = 0;
  }
  newData[field] = value;
  newData[costFieldName] = calculatePoCost(value, currentRowData["Purchase Price"]);
  sendData(value, currentRowData, dbFieldName, type, userId);
}

export const disableNumericArrows = (e) => {
  if (e.editorName === "dxNumberBox")
    e.editorOptions.step = 0;
}

export const clearFields = async (programId, categoryId, PoQtyReviewer, PoNotesReviewer, PoQtyMarket, PoNotesMarket, PoQtyApprover, PoNotesApprover, data, mutate, onUpdateStart, onUpdateEnd) => {
  if (onUpdateStart) onUpdateStart();
  await CleanUpdateFields(
    programId,
    categoryId,
    PoQtyReviewer,
    PoNotesReviewer,
    PoQtyMarket,
    PoNotesMarket,
    PoQtyApprover,
    PoNotesApprover
  )
  var newData = data.map((item) => {
    return {
      ...item,
      col_poQtyReviewer: PoQtyReviewer === 1 ? null : item.col_poQtyReviewer,
      col_poNotesReviewer: PoNotesReviewer === 1 ? null : item.col_poNotesReviewer,
      col_poQtyMarket: PoQtyMarket === 1 ? null : item.col_poQtyMarket,
      col_poNotesMarket: PoNotesMarket === 1 ? null : item.col_poNotesMarket,
      col_poQtyApprover: PoQtyApprover === 1 ? null : item.col_poQtyApprover,
      col_poNotesApprover: PoNotesApprover === 1 ? null : item.col_poNotesApprover
    };
  });
  mutate(newData);
  if (onUpdateEnd) onUpdateEnd();
}



export const clearFieldsGlobalGrid = async (roleId, internalId, categories, PoQtyReviewer, PoNotesReviewer, PoQtyMarket, PoNotesMarket, PoQtyApprover, PoNotesApprover, data, mutate) => {
  await CleanUpdateFieldsGlobalGrid(roleId, internalId);
  var newData = data.map((item) => {
    if (categories[item.programId].find(x => x.idCategory === item.categoryId)) {
      return {
        ...item,
        col_poQtyReviewer: PoQtyReviewer === 1 ? null : item.col_poQtyReviewer,
        col_poNotesReviewer: PoNotesReviewer === 1 ? null : item.col_poNotesReviewer,
        col_poQtyMarket: PoQtyMarket === 1 ? null : item.col_poQtyMarket,
        col_poNotesMarket: PoNotesMarket === 1 ? null : item.col_poNotesMarket,
        col_poQtyApprover: PoQtyApprover === 1 ? null : item.col_poQtyApprover,
        col_poNotesApprover: PoNotesApprover === 1 ? null : item.col_poNotesApprover
      };
    }
    return item;
  });
  mutate(newData);
}
