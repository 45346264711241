import { Popup, Toolbar } from "devextreme-react"
import DataGrid, { Column, Editing, Item, Scrolling } from "devextreme-react/cjs/data-grid"
import { useRef } from "react"
import { shareSavedSearch } from "../../actions/savedSearches/SavedSearchesActions";
import useSWR from "swr";
import { fetcher } from "../../actions";

export const ShareFilter = ({ visible, searchId, onClose }) => {
    const gridInstance = useRef();

    const {data, isLoading} = useSWR(`/api/SavedSearch/share/Users/${searchId}`, fetcher);

    const saveShareFilter = async (e) => {
        const changes = e?.changes;

        const payload = changes.map((change) => {
            return {
                userId: change.data.userId,
                selected: change.data.shared,
                searchId: searchId
            }
        })
        await shareSavedSearch(payload);
    }

    return (
        <Popup
            title="Share Filter"
            visible={visible}
            showTitle={true}
            dragEnabled={true}
            width={"50vw"}
            height={"50vh"}
            showCloseButton={true}
            onHiding={onClose}
        >
                <DataGrid
                    ref={gridInstance}
                    dataSource={data}
                    keyExpr="userId"
                    showBorders={true}
                    focusedRowEnabled={true}
                    columnAutoWidth={true}
                    showColumnLines={true}
                    showRowLines={true}
                    onSaved={saveShareFilter}
                    height={"100%"}
                    width={"100%"}
                    rowAlternationEnabled={true}>
                    <Scrolling mode="virtual" />
                    <Toolbar>
                    <Item name="saveButton"  />
                    </Toolbar>
                    <Editing mode="batch" allowUpdating={true} />
                    <Column dataField="userId" allowEditing={true} caption="User ID"></Column>
                    <Column dataField="userName" allowEditing={false} caption="User Name"></Column>
                    <Column dataField="shared" allowEditing={true} caption="Shared"></Column>

                </DataGrid>
        </Popup>
    )



}