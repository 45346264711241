import { useDispatch, useSelector } from "react-redux";
import { WizardModal } from "./components/wizard/wizardModal";
import { changedMenu } from "./actions/menu";
import { MENU_GLOBAL_GRID, MENU_MAIN_SELECTOR } from "./actions/menuTypes";
import apiClient from "./apis/items";
import { GlobalGridRenderer } from "./components/globalGrid/globalGridRenderer";
import { globalGridFilterChanged } from "./actions";

export const fetcherPost = params => url =>
    apiClient.post(url, params)
        .then(res => res?.data ?? [])
        .catch(error => {
            console.error('Error fetching data:', error);
            return [];
        });

export function GlobalGrid() {

    const showMainGridWizard = useSelector(state => state.menu.props?.openWizardModal);
    const dispatch = useDispatch();

    const onApplyFilter = (data, url) => {
        dispatch(changedMenu(MENU_GLOBAL_GRID, { openWizardModal: false }));
        dispatch(globalGridFilterChanged(url, data));
    }

    const handleClose = () => {
        dispatch(changedMenu(MENU_MAIN_SELECTOR, { openWizardModal: false}));
    }


    const renderWizardModal = () => {
            return (
                <WizardModal
                    open={true}
                    programId={1}
                    categoryId={1}
                    onApplyFilter={onApplyFilter}
                    onClose={() => handleClose()}
                    userId={0}
                />
            );
        }

    return (
        <div>
            {showMainGridWizard===true ? renderWizardModal() : <GlobalGridRenderer />}
        </div>
        
    );
}


