export const FETCH_ITEMS_LOADING = 'FETCH_ITEMS_LOADING';
export const FETCH_ITEMS = 'FETCH_ITEMS';

export const FETCH_ITEMS_BY_CATEGORY_LOADING = 'FETCH_ITEMS_BY_CATEGORY_LOADING';
export const FETCH_ITEMS_BY_CATEGORY = 'FETCH_ITEMS_BY_CATEGORY';

export const CHANGE_PO_LOADING = 'CHANGE_PO_LOADING';
export const CHANGE_PO_VALUE = 'CHANGE_PO_VALUE';

export const FETCH_KIT_LOADING = 'FETCH_KIT_LOADING';
export const FETCH_KIT = 'FETCH_KIT';

export const FETCH_RELATEDCOMPONENTS_LOADING = 'FETCH_RELATEDCOMPONENTS_LOADING';
export const FETCH_RELATEDCOMPONENTS = 'FETCH_RELATEDCOMPONENTS';

export const FETCH_USERINFO_LOADING = 'FETCH_USERINFO_LOADING';
export const FETCH_USERINFO = 'FETCH_USERINFO';

export const USER_INFORMATION_LOADED = 'USER_INFORMATION_LOADED';


export const FETCH_UNIQUECUSTOMERS_LOADING = 'FETCH_UNIQUECUSTOMERS_LOADING';
export const FETCH_UNIQUECUSTOMERS = 'FETCH_UNIQUECUSTOMERS';

export const FETCH_NETSUITEINTEGRATION_LOADING = 'FETCH_NETSUITEINTEGRATION_LOADING';
export const FETCH_NETSUITEINTEGRATION = 'FETCH_NETSUITEINTEGRATION';


export const FETCH_SETTINGS_LOADING = 'FETCH_SETTINGS_LOADING';
export const FETCH_SETTINGS = 'FETCH_SETTINGS';

export const FETCH_SETTINGS_ALL_LOADING = 'FETCH_SETTINGS_ALL_LOADING';
export const FETCH_SETTINGS_ALL = 'FETCH_SETTINGS_ALL';

export const POST_SETTINGS_LOADING = 'POST_SETTINGS_LOADING';
export const POST_SETTINGS = 'POST_SETTINGS';

export const POST_ITEMS_LOADING = 'POST_ITEMS_LOADING';
export const POST_ITEMS = 'POST_ITEMS';


export const FETCH_VENDORS_LOADING = 'FETCH_VENDORS_LOADING';
export const FETCH_VENDORS = 'FETCH_VENDORS';

export const FETCH_BACK_LOADING = 'FETCH_BACK_LOADING';
export const FETCH_BACK = 'FETCH_BACK';

export const FETCH_INVENTORY_LOADING = 'FETCH_INVENTORY_LOADING';
export const FETCH_INVENTORY = 'FETCH_INVENTORY';

export const FETCH_PROGRAMS_LOADING = 'POST_PROGRAMS_LOADING';
export const FETCH_PROGRAMS = 'POST_PROGRAMS';

export const FETCH_CATEGORIES_LOADING = 'FETCH_CATEGORIES_LOADING';
export const FETCH_CATEGORIES = 'FETCH_CATEGORIES';

export const FETCH_COLUMNS_LOADING = 'POST_COLUMNS_LOADING';
export const FETCH_COLUMNS = 'POST_COLUMNS';

export const FETCH_UPLOADEDDATA_LOADING = 'FETCH_UPLOADEDDATA_LOADING';
export const FETCH_UPLOADEDDATA = 'FETCH_UPLOADEDDATA';

export const FETCH_UPLOADERROR_LOADING = 'FETCH_UPLOADERROR_LOADING';
export const FETCH_UPLOADERROR = 'FETCH_UPLOADERROR';

export const FETCH_USERPERMISSIONS_LOADING = 'FETCH_USERPERMISSIONS_LOADING';
export const FETCH_USERPERMISSIONS = 'FETCH_USERPERMISSIONS';

export const FETCH_ROLEUSERS_ERROR = 'FETCH_ROLEUSERS_ERROR';
export const FETCH_ROLEUSERS_LOADING = 'FETCH_ROLEUSERS_LOADING';
export const FETCH_ROLEUSERS = 'FETCH_ROLEUSERS';

export const FETCH_FEATUREPERMISSIONS_LOADING = 'FETCH_FEATUREPERMISSIONS_LOADING';
export const FETCH_FEATUREPERMISSIONS = 'FETCH_FEATUREPERMISSIONS';

export const FETCH_MOHSELECTVALUES_LOADING = 'FETCH_MOHSELECTVALUES_LOADING';
export const FETCH_MOHSELECTVALUES = 'FETCH_MOHSELECTVALUES';


export const RESET_ITEMS = 'RESET_ITEMS';

export const FETCH_ADMIN_ROLES_LOADING = 'FETCH_ADMIN_ROLES_LOADING';
export const FETCH_ADMIN_ROLES = 'FETCH_ADMIN_ROLES';

export const FETCH_ADMIN_USERS_LOADING = 'FETCH_ADMIN_USERS_LOADING';
export const FETCH_ADMIN_USERS = 'FETCH_ADMIN_USERS';


export const FETCH_NETSUITE_LASTUPDATE_LOADING = 'FETCH_NETSUITE_LASTUPDATE_LOADING';
export const FETCH_NETSUITE_LASTUPDATE = 'FETCH_NETSUITE_LASTUPDATE';

export const FETCH_NETSUITE_FILELASTUPDATE_LOADING = 'FETCH_NETSUITE_FILELASTUPDATE_LOADING';
export const FETCH_NETSUITE_FILELASTUPDATE = 'FETCH_NETSUITE_FILELASTUPDATE';

export const ROLE_USER_ADDED_LOADING = "ROLE_USER_ADDED_LOADING";
export const ROLE_USER_ADDED = "ROLE_USER_ADDED";

export const ROLE_USER_DELETE_LOADING = "ROLE_USER_DELETE_LOADING";
export const ROLE_USER_DELETE = "ROLE_USER_DELETE";


export const ROLE_DELETE_LOADING = "ROLE_DELETE_LOADING";
export const ROLE_DELETE = "ROLE_DELETE";

export const ROLE_ADDED_LOADING = "ROLE_DELETE_LOADING";
export const ROLE_ADDED = "ROLE_DELETE";

export const USER_AUTHENTICATED_LOADING = "USER_AUTHENTICATED_LOADING";
export const USER_AUTHENTICATED = "USER_AUTHENTICATED";


export const ROLES_FEATURE_PERMISSIONS_LOADING = "ROLES_FEATURE_PERMISSIONS_LOADING";
export const ROLES_FEATURE_PERMISSIONS = "ROLES_FEATURE_PERMISSIONS";

export const ROLES_COLUMNS_PERMISSIONS_LOADING = "ROLES_COLUMNS_PERMISSIONS_LOADING";
export const ROLES_COLUMNS_PERMISSIONS = "ROLES_COLUMNS_PERMISSIONS";

export const ROLES_FEATURE_PERMISSIONS_UPDATE_LOADING = "ROLES_FEATURE_PERMISSIONS_UPDATE_LOADING";
export const ROLES_FEATURE_PERMISSIONS_UPDATE = "ROLES_FEATURE_PERMISSIONS_UPDATE";

export const ROLES_COLUMNS_PERMISSIONS_UPDATE_LOADING = "ROLES_COLUMNS_PERMISSIONS_UPDATE_LOADING";
export const ROLES_COLUMNS_PERMISSIONS_UPDATE = "ROLES_COLUMNS_PERMISSIONS_UPDATE";

export const USER_CATEGORIESPROGRAMS_LOADING = "USER_CATEGORIESPROGRAMS_LOADING";
export const USER_CATEGORIESPROGRAMS = "USER_CATEGORIESPROGRAMS";

export const USER_CATEGORIESPROGRAMS_DELETE_LOADING = "USER_CATEGORIESPROGRAMS_DELETE_LOADING";
export const USER_CATEGORIESPROGRAMS_DELETE = "USER_CATEGORIESPROGRAMS_DELETE";

export const USER_CATEGORIESPROGRAMS_CREATE_LOADING = "USER_CATEGORIESPROGRAMS_CREATE_LOADING";
export const USER_CATEGORIESPROGRAMS_CREATE = "USER_CATEGORIESPROGRAMS_CREATE";

export const FETCH_PROGRAMS_ALL_LOADING = "FETCH_PROGRAMS_ALL_LOADING";
export const FETCH_PROGRAMS_ALL = "FETCH_PROGRAMS_ALL";

export const FETCH_CATEGORIES_ALL_LOADING = "FETCH_CATEGORIES_ALL_LOADING";
export const FETCH_CATEGORIES_ALL = "FETCH_CATEGORIES_ALL";

export const FETCH_CATEGORIES_ALL_LOADING_BY_PROGRAM = "FETCH_CATEGORIES_ALL_LOADING_BY_PROGRAM";
export const FETCH_CATEGORIES_ALL_BY_PROGRAM = "FETCH_CATEGORIES_ALL_BY_PROGRAM";

export const ROLES_FEATURE_VIEWER_ALL_LOADING = "ROLES_FEATURE_VIEWER_ALL_LOADING";
export const ROLES_FEATURE_VIEWER_ALL = "ROLES_FEATURE_VIEWER_ALL";

export const ROLES_COLUMNS_VIEWER_ALL_LOADING = "ROLES_COLUMNS_VIEWER_ALL_LOADING";
export const ROLES_COLUMNS_VIEWER_ALL = "ROLES_COLUMNS_VIEWER_ALL";

export const CHANGE_SELECTED_PROGRAM = "CHANGE_SELECTED_PROGRAM";
export const CHANGE_SELECTED_CATEGORY = "CHANGE_SELECTED_CATEGORY";
export const OPEN_WIZARD_MODAL = "OPEN_WIZARD_MODAL";

export const GRIDTEMPLATE_CREATE_LOADING = "GRIDTEMPLATE_CREATE_LOADING";
export const GRIDTEMPLATE_CREATE = "GRIDTEMPLATE_CREATE";

export const GRIDTEMPLATE_GET_LOADING = "GRIDTEMPLATE_GET_LOADING";
export const GRIDTEMPLATE_GET = "GRIDTEMPLATE_GET";


export const GRIDTEMPLATE_SET_SELECTED_LOADING = "GRIDTEMPLATE_SET_SELECTED_LOADING";
export const GRIDTEMPLATE_SET_SELECTED = "GRIDTEMPLATE_SET_SELECTED";


export const FETCH_PERIODS_LOADING = "FETCH_PERIODS_LOADING";
export const FETCH_PERIODS = "FETCH_PERIODS";

export const FETCH_SUMMARY_PERIODS_LOADING = "FETCH_SUMMARY_PERIODS_LOADING";
export const FETCH_SUMMARY_PERIODS = "FETCH_SUMMARY_PERIODS";

export const FETCH_CURRENT_PERIODS_LOADING = "FETCH_CURRENT_PERIODS_LOADING";
export const FETCH_CURRENT_PERIODS = "FETCH_CURRENT_PERIODS";

export const FETCH_GRAPHIC_PERIODS_LOADING = "FETCH_GRAPHIC_PERIODS_LOADING";
export const FETCH_GRAPHIC_PERIODS = "FETCH_GRAPHIC_PERIODS";

export const FETCH_CONSOLIDATE_LOADING = "FETCH_CONSOLIDATE_LOADING";
export const FETCH_CONSOLIDATE = "FETCH_CONSOLIDATE";

export const FETCH_VENDOR_LOADING = "FETCH_VENDOR_LOADING";
export const FETCH_VENDOR = "FETCH_VENDOR";

export const POST_USERS_LOADING = 'POST_USERS_LOADING';
export const POST_USERS = 'POST_USERS';

export const MENU_CLICKED = 'MENU_CLICKED';

export const DATAGRID_VALUE_CHANGED = 'DATAGRID_VALUE_CHANGED';

export const GLOBAL_GRID_FILTER_CHANGED = 'GLOBAL_GRID_FILTER_CHANGED';
