import { SelectBox } from 'devextreme-react';
import React, { useEffect, useState } from 'react';
import useSWR from 'swr';
import { fetcher } from '../../actions';


export const SearchSelectBox = ({ type, onSelectedSearchChange }) => {
    const { data, isLoading, isError } = useSWR(`/api/SavedSearch/${type}/all`, fetcher);
    const [searches, setSearches] = useState([]);

    const handleSelectedSearchChange = ({ value }) => {
        if (value === null) {
            onSelectedSearchChange(null, null);
            return;
        }
        const templateId = value;
        const selectedSearchName = searches.find(a => a.id === templateId)?.name;
        const selectedSearch = searches.find(a => a.id === templateId);
        onSelectedSearchChange(selectedSearchName, selectedSearch);
    };

    const renderItem = ({ name, shared }) => {
        const style = {
            display: 'inline-block',
            fontWeight: shared ? 'bold' : 'normal',
        };
    
        return (
            <div>
                <div style={style}>{name}</div>
            </div>
        );
    };



    useEffect(() => {
        if (data) {
            const records = data.map((search) => {
                let query;
                try {
                    query = JSON.parse(search.query);
                } catch (e) {
                    console.error('Invalid JSON in search query:', search.query);
                    query = {};
                }
                return {
                    id: search.id,
                    name: search.searchName + (search.shared ? " (Shared)" : ""),
                    query: query,
                    shared : search.shared
                };
            });
            setSearches(records);
        } else {
            setSearches([]);
        }
    }, [data]);
    return (
        <>
            <SelectBox
                searchEnabled={true}
                dataSource={searches}
                placeholder="Saved Templates"
                showClearButton={true}
                valueExpr="id"
                displayExpr="name"
                onValueChanged={(e) => handleSelectedSearchChange(e)}
                itemRender={renderItem}
            />
        </>
    );

}


