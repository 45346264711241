import { TreeView } from "devextreme-react";
import { useEffect, useRef, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import { fetchSettings, onChangeSelectedProgram, fetchLastUpdateFiles, globalGridFilterChanged } from "../../actions";
import CustomStore from 'devextreme/data/custom_store';
import moment from 'moment';
import { Tooltip } from 'devextreme-react/tooltip';
import { GlobalGridLink } from "../Menu/components/GlobalGridLink";
import { changedMenu } from "../../actions/menu";
import { MENU_FILES, MENU_GRID_RENDER, MENU_GRID_TABS } from "../../actions/menuTypes";
import { NetsuiteUpdateButton } from "../tools/NetsuiteUpdateButton";
import { NetsuiteLastUpdateLabel } from "../tools/NetsuiteLastUpdateLabel";
import { fetcher } from "../../actions";
import useSWR from "swr";
import { Autocomplete } from 'devextreme-react/autocomplete';

export const MainSelector = (props) => {
    const { onChange } = props;
    const programs = useSelector(state => state.userInfo.programs);
    const categories = useSelector(state => state.userInfo.categories);
    const lastFileUpdate = useSelector(state => state.items.netsuiteFileLastUpdate);
    const [state, setState] = useState([]);
    const [searchValue, setSearchValue] = useState([]);
    const dispatch = useDispatch();
    const treeViewRef = useRef(null);


    const reportListStore = new CustomStore({
        key: 'name',
        load: (loadOptions) => {
            if (searchValue?.length >= 3) {
                return fetcher(`/api/Items/reportListNames?list=${searchValue}`).then(reportList => {
                    return reportList
                }).catch(() => {
                    throw new Error('Data Loading Error');
                });
            }
            return [];
        }
    });

    const {
        data: dataSource,
    } = useSWR(searchValue != null ?
        `/api/Items/reportListNames?list=${searchValue}` : null,
        fetcher
    );

    const getFilterDataFromValue = (value, dataSource) => {
        const filterRow = dataSource?.filter(x => x.name === value);
        const internalID = filterRow[0]?.id;
        const partname = filterRow[0]?.partname;
        const program = filterRow[0]?.program;
        const category = filterRow[0]?.category;
        const filterObject = { "filterName": "test Filter", "columnNameList": [{ "columnId": 59, "filterCriteria": "equals", "filterValue": internalID, "operator": "And" }, { "columnId": 1, "filterCriteria": "equals", "filterValue": partname, "operator": "And" }, { "columnId": 91, "filterCriteria": "equals", "filterValue": program, "operator": "And" }, { "columnId": 92, "filterCriteria": "equals", "filterValue": category, "operator": "" }] }
        return filterObject;
    };


    const handleValueChange = useCallback((e, dataSource) => {
        if (!e?.value?.includes('ID')) {
            setSearchValue(e?.value);
        }
        else {
            const filterData = getFilterDataFromValue(e?.value, dataSource);
            dispatch(globalGridFilterChanged('/api/Items/itemColumnsMassiveFilter', filterData));
            dispatch(changedMenu(MENU_GRID_RENDER));
        }
    }, []);


    const buildMenuTree = (programs, categories) => {
        if (programs && categories) {
            const data = programs.map((program) => {
                return {
                    ID: program.programId,
                    programId: program.programId,
                    name: program.name
                }
            });

            const keys = Object.keys(categories);
            keys.forEach((key) => {
                categories[key].forEach((category) => {
                    if (category.records !== 0) {
                        data.push({
                            ID: `${key}-${category.categoryId}`,
                            programId: key,
                            name: category.categoryName,
                            categoryId: category.categoryId,
                            parentId: key
                        });
                    }
                });
            });
            setState(data);
        }
    }

    const selectItem = (e) => {
        if (e.node.children.length === 0) {
            const programId = +e.itemData.programId;
            dispatch(onChangeSelectedProgram(programId));
            dispatch(fetchSettings(programId));
            onChange(e.itemData.categoryId);
            dispatch(changedMenu(MENU_GRID_TABS))
        }
        else {
            if (e.node.expanded) {
                treeViewRef.current.instance.collapseItem(e.itemData.ID);
            }
            else {
                treeViewRef.current.instance.expandItem(e.itemData.ID);
            }
        }
    }

    useEffect(() => {
        buildMenuTree(programs, categories);
        if (!lastFileUpdate) {
            dispatch(fetchLastUpdateFiles(1));
        }
    }, []);


    const renderFileLastUpdate = (type) => {
        if (lastFileUpdate) {
            const lastUpdate = lastFileUpdate.find((file) => file.Code === type);
            if (lastUpdate) {
                return renderDate(lastUpdate.UpdatedDate);
            }
        }
        return "No data";
    }

    const filesLinkClick = (type) => {
        dispatch(changedMenu(MENU_FILES, { type: type }));
    }


    const renderDate = (date) => {
        if (date) {
            const date1 = new Date(date);
            let dateFormat1 = moment(date1).format('YYYY-MM-DD HH:mm');
            return <span>{dateFormat1}</span>;
        }
        return "No data";
    }

    return (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: "5vh", height: '100vh', gap: "80px" }}>
            <div style={{ display: 'flex', flexDirection: "column", gap: "3vh" }}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: "40px" }}>
                    <div id="Action-Containers" >
                        <NetsuiteUpdateButton origin={"main-menu"} />
                        <Tooltip
                            target="#btnNetsuite"
                            showEvent="mouseenter"
                            hideEvent="mouseleave"
                            hideOnOutsideClick={false}
                        >
                            <div>Retrieve the latest data changes from Netsuite.</div>
                        </Tooltip>
                    </div>
                    <div>
                        <NetsuiteLastUpdateLabel origin={"main-menu"} />
                    </div>
                </div>
                <hr></hr>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'left', gap: "40px" }}>
                    <div>
                        <Link to="#" onClick={() => filesLinkClick(1)} style={{ color: "#161616" }}>Consolidated Shipment</Link>
                    </div>
                    <div>{renderFileLastUpdate("filesConsolidate")}</div>
                </div>
                <hr></hr>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'left', gap: "40px" }}>
                    <div><Link to="#" onClick={() => filesLinkClick(2)} style={{ color: "#161616" }}>Vendor Inventory</Link></div>
                    <div>{renderFileLastUpdate("filesVendor")}</div>
                </div>
            </div>
            <div style={{ display: 'flex', flexDirection: "column" }}>
                <div style={{ display: 'flex', alignItems: 'left', flexDirection: "row", marginBottom: '0.5rem' }}>
                    <Autocomplete
                        dataSource={reportListStore}
                        value={searchValue}
                        displayExpr="name"
                        valueExpr="name"
                        onValueChanged={(e) => handleValueChange(e, dataSource)}
                        placeholder="Search Field"
                        width={'20rem'}
                        showClearButton={true}
                    />
                </div>
                <div style={{ display: 'flex', alignItems: 'left', marginBottom: '0.5rem' }}>
                    <GlobalGridLink text="Filter Wizard" style={{ color: "#161616" }} />
                </div>
                <div className="form">
                    <TreeView id="simple-treeview"
                        ref={treeViewRef}
                        items={state}
                        dataStructure="plain"
                        parentIdExpr="parentId"
                        displayExpr="name"
                        keyExpr="ID"
                        width={300}
                        onItemClick={selectItem}
                    />
                </div>
            </div>
        </div>
    );
}
export default MainSelector;
