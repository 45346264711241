import { GLOBAL_GRID_FILTER_CHANGED } from "../actions/types";


const globalGridReducer =  (state = {}, action) => {
    switch (action.type) {
        case GLOBAL_GRID_FILTER_CHANGED:
            return {...state, 'url': action.payload.url, 'data': action.payload.data};
        default:
            return state;
    }
};

export default globalGridReducer;