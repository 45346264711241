import PageviewIcon from '@mui/icons-material/Pageview';
import { Validator, RequiredRule } from "devextreme-react/validator";
import TextBox from "devextreme-react/text-box";
import { Button } from 'devextreme-react';
import { addSavedSearchByColumn, deleteSavedSearch, getSavedSearchByColumnByName, updateSavedSearchByColumn } from '../../actions/savedSearches/SavedSearchesActions';
import { confirm } from 'devextreme/ui/dialog';
import { useState } from 'react';
import { ShareFilter } from './ShareFilter';

export const ColumnsFilterReview = (props) => {
    const { filterRows, filterName, setFilterName, selectedSearch } = props;
    const [showShareTemplate, setShowShareTemplate] = useState(false);


    const sharedSearch = selectedSearch?.shared;
    const searchId = selectedSearch?.id;

    console.log(selectedSearch);

    const filterNameTextBoxStyle = filterName === null ? { marginBottom: "0.625rem", marginTop: "0.625rem", borderColor: "#880808" } : { marginBottom: "0.625", marginTop: "0.625" };
    const logicalOperatorOptions = [
        {
            id: 1, text: 'Or'
        },
        {
            id: 2, text: 'And'
        },
    ];

    const getLogicalOperatorText = (id) => {
        return logicalOperatorOptions?.find(
            a => a.id === id
        )?.text;
    }
    const saveTemplate = async () => {
        var existingSearch = await getSavedSearchByColumnByName(filterName);
        if (existingSearch !== "") {
            let result = confirm("<i>The filter already exists do you want to overwrite it?</i>", "Confirm updating existing filter");
            result.then(async (dialogResult) => {
                if (dialogResult) {
                    await updateSavedSearchByColumn(filterName, filterRows);
                    alert("Filter Updated Successfully");
                }
            });
        }
        else {
            await addSavedSearchByColumn(filterName, filterRows);
            alert("Filter added Successfully");
        }
    }

    const shareTemplate = () => {
        setShowShareTemplate(true);
    }

    const DeleteTemplate = async () => {
        let result = confirm("<i>Are you sure you want to delete the filter?</i>", "Confirm deleting filter");
        result.then(async (dialogResult) => {
            if (dialogResult) {
                await deleteSavedSearch(searchId);
                alert("Filter Deleted Successfully");
            }
        });
    }

    console.log(filterRows);
    return (
        <div>
            <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                <div style={{ marginTop: "0.1rem" }}>
                    <PageviewIcon fontSize="large" />
                </div>
                <div >
                    <div>
                        <span style={{ fontSize: "2em", fontFamily: "verdana", fontWeight: "bold", marginLeft: "0.5rem", marginBottom: "3rem" }}>Preview</span><br />
                    </div>
                </div>
            </div>
            <div className="flex-item" style={{ marginTop: "2rem" }}>
                <span>Filter Name *</span>
            </div>
            <div style={{ display: "flex", width: "100%", flexDirection: "column", marginLeft: "1rem", marginRight: "1rem", backgroundColor: "#fcfcfc", paddingTop: "1rem", paddingBottom: "1rem", paddingLeft: "1rem", paddingRight: "1rem" }}>
                <div style={{ paddingTop: "1rem" }}>
                    <TextBox
                        onChange={(e) => setFilterName(e.component._changedValue)}
                        placeholder="Add Filter Name"
                        defaultValue={filterName}
                        style={filterNameTextBoxStyle}
                    >
                        <Validator>
                            <RequiredRule IsRequired="true" message="field is required" />
                        </Validator>
                    </TextBox>
                </div>
                <div style={{ paddingTop: "3px" }}>
                    <Button
                        text="Save filter"
                        type="normal"
                        stylingMode="contained"
                        onClick={saveTemplate}
                    />
                    {
                        sharedSearch === false ?
                        <>
                        <Button
                                text="Share Filter"
                                type="normal"
                                stylingMode="contained"
                                onClick={shareTemplate}
                            />
                            <Button
                                text="Delete Filter"
                                type="normal"
                                stylingMode="contained"
                                onClick={DeleteTemplate}
                            />
                        </>
                            : ""
                    }
                </div>

            </div>

            <div>
                {filterRows?.map((filterRow) => (
                    <div>
                        <div style={{ display: "flex", flexDirection: "row", marginLeft: "1rem", backgroundColor: "#fcfcfc", paddingTop: "1rem", paddingBottom: "1rem", paddingLeft: "1rem", paddingRight: "1rem" }}>
                            <div style={{ display: "flex" }}>
                                <span style={{ fontSize: "1em", marginLeft: "0.7rem", marginRight: "1rem" }}>{filterRow?.column} </span><br />
                            </div>
                            <div>
                                <span style={{ fontSize: "1em", color: "#2b7cff", marginRight: "1rem" }}>{filterRow?.operatorName} </span><br />
                            </div>
                            {filterRow?.filterValue2 !== null && <div>
                                <span style={{ fontSize: "1em", marginRight: "0.2rem" }}>{`${filterRow?.filterValue2} -`} </span><br />
                            </div>}
                            <div>
                                <span style={{ fontSize: "1em", marginRight: "1rem" }}>{filterRow?.filterValue} </span><br />
                            </div>
                            <div>
                                <span style={{ fontSize: "1em", color: "#0f6bff" }}>{getLogicalOperatorText(filterRow?.logicalOperator)} </span><br />
                            </div>
                        </div>
                    </div>
                ))
                }
            </div >
            { showShareTemplate && <ShareFilter visible={showShareTemplate} searchId={searchId} onClose={() => setShowShareTemplate(false)} />}
        </div >
    );
}
