/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useRef, useEffect, useCallback } from "react";
import "./mainGrid.css";
import "devextreme/dist/css/dx.light.css";
import TreeList, {
  Editing,
  Selection,
  Column,
  FilterRow,
  HeaderFilter,
  FilterPanel,
  Scrolling,
  ColumnChooser,
  SearchPanel,
  Toolbar,
  Item,
  ColumnFixing,
  KeyboardNavigation,
  LoadPanel,
  Search,
  StateStoring,
} from "devextreme-react/tree-list";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchSettings,
  getMohSelectValues,
} from "../../actions";
import { SettingsModal } from "../grid/dialog/Settings/SettingsModal";
import { LogModal } from "../grid/dialog/Log/LogModal";
import {
  renderL3MvsL12M,
  renderProjectedMoHAfterL12M,
  renderProjectedMoHAfterL3M,
  rendery2y1,
  renderYoyCell,
  renderYoyStabilityCell,
  renderIconQtyCell
} from "./customCellStyle";
import {
  setCellValue,
  setCellValueAndCost,
  onCellPrepared,
  handleNodesInitialized,
  disableNumericArrows
} from "./customGridActions";
import ExportModal from "../grid/dialog/Export/ExportModal";
import {
  CalculateAdditionalMoHLoad,
  calculatePoCostApprover,
  calculatePoCostMarket,
  calculatePoReviewerCost,
  calculateRevisedPoQty,
  calculateY2ToY1,
  calculateRemainingEstMoh,
} from "../../common/formulas";
import { excelExport } from "../../common/Export";
import { GridTooltips } from "../gridTooltip/gridTooltips";
import { SaveGridTemplateDialog } from "../grid/dialog/GridTemplate/saveGridTemplate";
import { GetColumnsByGrid, customRenderColumn } from "../../common/Columns";
import { LinearProgress } from "@mui/material";
import PropTypes from 'prop-types';
import MohSelectBox from "./toolbar/Components/mohSelectBox";
import { getModifierValueId } from "../../common/mohFunctions";
import ToolBarButton from "./toolbar/Components/toolbarButton";
import ToolBarDropDownButton from "./toolbar/Components/toolbarDropDownButton";
import { ExportOptions } from "./Data/ExportOptions";
import { useInterval } from 'usehooks-ts'
import { getActiveGridTemplatesByUserProgramCategory } from "../../actions/gridTemplates/gridTemplatesActions";
import { clearCategoryGrid, clearGlobalGrid } from "./functions/cleanFields";
import { NetsuiteUpdateButton } from "../tools/NetsuiteUpdateButton";
import { GridSettingsModal } from "../grid/dialog/GridSettings/GridSettingsModal";
import { getGridAttributesByDefault, gridAttributesKey } from "../../common/gridSettings";
import { SumByColumn, updateColumnSum, initColumnSum, useRefSumByColumn } from "../SumByColumn/SumByColumn";
import { SumColumnsModal } from "../grid/dialog/SumColumns/SumColumnsModal";
import { presetPopup } from "../grid/dialog/presetPopup/presetPopup";
import { getPreferredVendorColumn } from "./columnsProps/preferredVendorColumn";
import { getCurrentSystemsColumn } from "./columnsProps/currentSystemsColumn";
import { getInventoryColumns } from "./columnsProps/inventoryColumns";
import { getUniqueCustomColumns } from "./columnsProps/uniqueCustomColumns";
import { getBackOrderAndCommittedColumns } from "./columnsProps/backOrderAndCommittedColumns";
import { getPurchaseOrderColumn } from "./columnsProps/purchaseOrderColumn";
import { getVendorInventoryColumn } from "./columnsProps/vendorInventoryColumn";
import { getInboundColumn } from "./columnsProps/inboundColumn";

const MainGrid = (props) => {

  MainGrid.propTypes = {
    data: PropTypes.array,
    error: PropTypes.bool,
    isLoading: PropTypes.bool,
    mutate: PropTypes.func,
    onMohModifierChange: PropTypes.func.isRequired,
    programId: PropTypes.number.isRequired,
    categoryId: PropTypes.number.isRequired,
    selectedModifier: PropTypes.number.isRequired,
    onUpdateStart: PropTypes.func.isRequired,
    onUpdateEnd: PropTypes.func.isRequired,
  };

  const { data, error, isLoading, mutate, onMohModifierChange, programId, categoryId, onUpdateStart, onUpdateEnd } = props;
  const columns = GetColumnsByGrid(1);
  const dispatch = useDispatch();
  const settings = useSelector(state => state.settings.data);
  const storageKey = `storageKey-${programId}-${categoryId}`;
  const gridInstance = useRef(null);
  const user = useSelector(state => state.userInfo.userAuthenticated);
  const programsList = useSelector(state => state.userInfo.programs);
  const categoriesList = useSelector(state => state.userInfo.categories);
  const currentPermissions = useSelector(state => state.userInfo.permissions);
  const selectedRoleId = useSelector(state => state.userInfo.selectedRole);
  const currentRole = useSelector(state => state.userInfo.selectedRole);
  const groupId = `Group${programId}${categoryId}`;
  const [selectedModifier, setSelectedModifier] = useState(1);
  const [gridSettings, setGridSettings] = useState(getGridAttributesByDefault());
  // const [showGrid, setShowGrid] = useState(false);
  const incomingSignalrGridData = useSelector(state => state.signalR?.gridData);
  const [mohOptions, setMohOptions] = useState([]);

  let totalSumByColumn = initColumnSum();
  const {refSumByCol, refSumByColDisplay, setSumByColumn, getSumByColumn, setSumByColumnDisplay} = useRefSumByColumn();

  useEffect(() => {
    dispatch(fetchSettings(programId));
  }, [programId]);

  useEffect(() => {
    const getSelectValuesAsync = async () => {
      let mohOptions = [];
      if (!currentRole) return mohOptions;
      const gridModifiers = await getMohSelectValues(currentRole);
      if (gridModifiers) {
        mohOptions = Object.values(gridModifiers);
      }
      setMohOptions(mohOptions);
    };
    getSelectValuesAsync();
  }, [currentRole]);


  useEffect(() => {
    const loadTemplateAndUpdateGrid = async () => {
      try {
        if (!gridInstance) return;
        await loadCurrentTemplate(storageKey);
      } catch (error) {
        console.error('Error loading current template:', error);
      }
      finally {
        //setShowGrid(true);
      }
    };
    loadTemplateAndUpdateGrid();

  }, [storageKey]);

  useEffect(() => {
    const updateGridData = async () => {
      if (incomingSignalrGridData) {
          if (gridInstance) {
            let dataSource = gridInstance.current.instance.getDataSource();
            const store = dataSource.store();
            try {
              await store.update(incomingSignalrGridData.keyItem, { [incomingSignalrGridData.fieldName]: incomingSignalrGridData.fieldValue });
              dataSource.load();
            } catch (error) {
              console.error(error);
            }
          }
        }
    };

    updateGridData();
  }, [incomingSignalrGridData, programId, categoryId, gridInstance]);

  useInterval(
    () => {
      const saveTemplate = async () => {
        try {
          const state = gridInstance.current.instance.state();
          await saveCurrentState(storageKey, state);
        } catch (error) {
          console.error('Error saving current template:', error);
        }
      };
      saveTemplate();
    },
    30000
  );

  const showLogDialog = (e) => {
    openPopupLog({internalId: e.row.data.InternalId, name: e.row.data.Name, categoryId: e.row.data.categoryId, programId: e.row.data.programId});
  };

  const showExportDialog = () => {
    openPopupExport();
  };

  const showPreferences = () => {
    openPopupSettings();
  };

   const showSumColumns = () => {
    openPopupSumColumns();
  };

  const showSaveColumnConfiguration = () => {
    openPopupSaveColumnConfiguration();
  };

  const handleSaveSettings = () => {
    closePopupSettings();
    mutate();
    dispatch(fetchSettings(programId));
  };

  const showGridSettings = () => {
    openPopupGridSettings({ ...gridSettings });
  };

  const handleSaveGridSettings = ({ columnLines, rowLines, borders, rowAlternation }) => {
    const gridAttributes = { columnLines, rowLines, borders, rowAlternation };
    setGridSettings(gridAttributes);
    localStorage.setItem(gridAttributesKey, JSON.stringify(gridAttributes));
    closePopupGridSettings();
  };
  
  const recalculateValues = (value) => {
    const selectedValue = getModifierValueId(value);
    onMohModifierChange(selectedValue);
    setSelectedModifier(selectedValue);
  };

  const isColVisible = useCallback((code) => {
    if (!currentPermissions) {
      return false;
    }
    let column = currentPermissions.filter((x) => x.ColumnCode === code)[0];
    return !!column;
  }, [currentPermissions]);

  const isColEditable = useCallback((code) => {
    if (!currentPermissions) {
      return false;
    }
    let column = currentPermissions.filter((x) => x.ColumnCode === code)[0];
    if (!column) {
      return false;
    }
    return column.Permission === 1;
  }, [currentPermissions]);

  const cleanUpdateFields = () => {
    const cleanUpdateFieldsAsync = async () => {
      if (programId === 0 && categoryId === 0) {
        await clearGlobalGrid(data, mutate, selectedRoleId, categoriesList, gridInstance, currentPermissions);
      }
      else {
        await clearCategoryGrid(data, mutate, programId, categoryId, currentPermissions);
      }
    }
    cleanUpdateFieldsAsync();
  };

  const renderDialog = useCallback((visible, component) => {

    if (visible) {
      return component;
    }
    return <></>;
  }, []);

  const loadCurrentTemplate = async (key) => {
    console.log("loadCurrentTemplate from local", key);
    try {
      let localTemplate = localStorage.getItem(key);
      if (localTemplate) {
        return JSON.parse(localTemplate);
      }
      console.log("loadCurrentTemplate from DB", key);
      const dbTemplate = await getActiveGridTemplatesByUserProgramCategory(user.id, programId, categoryId);
      localStorage.setItem(key, dbTemplate.template);
      console.log("dbTemplate", dbTemplate);
      return JSON.parse(dbTemplate.template);

    } catch (error) {
      console.error('Error loading current template:', error);
    }
    return null;
  };

  const saveCurrentState = async (key, state) => {
    try {
      console.log("saveCurrentState", key);
      const currentState = JSON.stringify(state);
      localStorage.setItem(key, currentState);
    } catch (error) {
      console.error('Error saving current template:', error);
    }
  };

  const handleTemplateChange = (currentTemplate) => {
    const state = JSON.parse(currentTemplate);
    gridInstance.current.instance.state(state);
  };

  const onContentReady = () => {
    updateColumnSum(totalSumByColumn, setSumByColumn, getSumByColumn, setSumByColumnDisplay);
  };

  const onNodesInitialized = (e) => {
    totalSumByColumn = handleNodesInitialized(e);
    updateColumnSum(totalSumByColumn, setSumByColumn, getSumByColumn, setSumByColumnDisplay);
  };


  const showExport = (e) => {
    let selectedOption = e.itemData.id;
    if (selectedOption === 1) {
      let currentData = [];
      gridInstance.current.instance.forEachNode((x) =>
        x.visible === true ? currentData.push(x.data) : ""
      );
      excelExport(gridInstance.current.instance, currentData);
    } else if (selectedOption === 2) {
      showExportDialog();
    }
  };


  const isAllowedColumn = useCallback((columnCode) => {
    const AllowedColumns = ["col_id", "col_program", "col_category"];
    return AllowedColumns.includes(columnCode);
  }, []);

  const renderColumn = useCallback((columnKey, component) => {
    if (isColVisible(columnKey) || isAllowedColumn(columnKey)) {
      const extraProps = { cssClass: "tooltip-target-" + columnKey };
      return customRenderColumn(columnKey, component, extraProps);
    }
    return "";
  }, [isColVisible, isAllowedColumn]);

  const saleAmountEditorOptions = { format: '$#,##0.##', showClearButton: true };

  const headerCellRender = (data) => {
    let text = data.column.caption;
    return <p style={{ whiteSpace: "normal" }}>{text}</p>;
  };

  const onEditorPreparing = (e) => {
    disableNumericArrows(e);
  };

  const getProgramName = (rowData, programsList) => {
    const programId = rowData["programId"];
    if (programId === null) return null;
    const program = programsList.find((x) => x.programId === programId);
    return program ? program.name : null;
  }

  const getCategoryName = (rowData, CategoryList) => {
    const programId = rowData["programId"];
    const categoryId = rowData["categoryId"];
    if (programId === null || categoryId === null) return null;
    const programCategories = CategoryList[programId];
    if (!programCategories) return null;
    const category = programCategories.find((x) => x.categoryId === categoryId);
    return category ? category.categoryName : null;
  }

  const addMenuItems = (e) => {
    if (e.row?.rowType === "data") {
      // e.items can be undefined
      if (!e.items) e.items = [];
      // Add a custom menu item
      e.items.push({
        text: 'View Editable Fields Log',
        onItemClick: () => {
          if (e.row.rowType === "data") {
            showLogDialog(e);
          }
        }
      });
      if (programId === 0 && categoryId === 0) {
        e.items.push(
          {
            text: 'Settings',
            onItemClick: () => {
              openPopupSettings({ programId: e.row.data.programId, categoryId: e.row.data.categoryId });
            }
          });
      }
    }
  }


  const onEditingStart = (e) => {
    if (!categoriesList[e.data.programId].find(x => x.categoryId === e.data.categoryId)) {
      e.cancel = true;
    }
  }

  const filterBySelectedRows = (e) => {
    const selectedRows = gridInstance.current.instance.getSelectedRowsData();
    const selectedNames = selectedRows.map(x => x.Name);
    gridInstance.current.instance.columnOption("Name", "filterValues", selectedNames)
  }

  const {render: LogModalRender, openPopup: openPopupLog} = presetPopup({ Popup: LogModal });
  const {render: ExportModalRender, openPopup: openPopupExport} = presetPopup({Popup: ExportModal });
  const {render: GridSettingsModalRender, openPopup: openPopupGridSettings, closePopup: closePopupGridSettings} = presetPopup({ Popup: GridSettingsModal, props: { onSavedData: handleSaveGridSettings }});
  const {render: SumColumnsModalRender, openPopup: openPopupSumColumns} = presetPopup({ Popup: SumColumnsModal, props: { getSumByColumn, refSumByColDisplay } });
  const {render: SettingsModalRender, openPopup: openPopupSettings, closePopup: closePopupSettings} = presetPopup({ Popup: SettingsModal,
    props: { programId: programId, categoryId: categoryId, onSavedData: handleSaveSettings }
  });
  const {render: SaveGridTemplateDialogRender, openPopup: openPopupSaveColumnConfiguration} = presetPopup({ Popup: SaveGridTemplateDialog,
    props: { programId: programId, categoryId: categoryId, userId: user.id, storageKey: storageKey, onTemplateChange: handleTemplateChange }
  });

  const getColumnCode = (col) => {
    if (isColVisible(col.localColumnKey) || isAllowedColumn(col.localColumnKey)) {
      return { ...col, allowEditing: col.allowEditing?? isColEditable(col.localColumnKey) }
    }
    else return null;
  }

  const {render: PreferredVendorModalRender, colProps: columnPreferredVendor} = getPreferredVendorColumn();
  const {render: ActiveKitsModalRender, colProps: columnCurrentSystems} = getCurrentSystemsColumn();
  const {render: InventoryModalRender, colProps: inventoryColumns} = getInventoryColumns();
  const {render: UniqueCustomerModalRender, colProps: uniqueCustomColumns} = getUniqueCustomColumns();
  const {render: BackModalRender, colProps: bOAndCommittedTotalColumns} = getBackOrderAndCommittedColumns();
  const {render: PurchaseOrderModalRender, colProps: purchaseOrderColumn} = getPurchaseOrderColumn();
  const {render: VendorInventoryModalRender, colProps: vendorInventoryColumn} = getVendorInventoryColumn();
  const {render: ConsolidateModalRender, colProps: inboundColumn} = getInboundColumn();
  const col_preferredVendor = getColumnCode(columnPreferredVendor);
  const col_activeKits = getColumnCode(columnCurrentSystems);
  const col_qohTotal = getColumnCode(inventoryColumns.col_qohTotal);
  const col_qohCa = getColumnCode(inventoryColumns.col_qohCa);
  const col_qohNC = getColumnCode(inventoryColumns.col_qohNC);
  const col_availTotal = getColumnCode(inventoryColumns.col_availTotal);
  const col_availCA = getColumnCode(inventoryColumns.col_availCA);
  const col_availNC = getColumnCode(inventoryColumns.col_availNC);
  const col_mod = getColumnCode(inventoryColumns.col_mod);
  const col_availPoModBo = getColumnCode(inventoryColumns.col_availPoModBo);
  const col_refinish = getColumnCode(inventoryColumns.col_refinish);
  const col_scrDent = getColumnCode(inventoryColumns.col_scrDent);
  
  const col_y3 = getColumnCode(uniqueCustomColumns.col_y3);
  const col_y2 = getColumnCode(uniqueCustomColumns.col_y2);
  const col_y1 = getColumnCode(uniqueCustomColumns.col_y1);
  const col_y0 = getColumnCode(uniqueCustomColumns.col_y0);
  const col_l12M = getColumnCode(uniqueCustomColumns.col_l12M);
  const col_l3M = getColumnCode(uniqueCustomColumns.col_l3M);
  const col_uniqueCustomerCount = getColumnCode(uniqueCustomColumns.col_uniqueCustomerCount);
  const col_topBuyerShare = getColumnCode(uniqueCustomColumns.col_topBuyerShare);
  const col_topBuyerName = getColumnCode(uniqueCustomColumns.col_topBuyerName);
  const col_exclusions = getColumnCode(uniqueCustomColumns.col_exclusions);

  const col_commitTotal = getColumnCode(bOAndCommittedTotalColumns.col_commitTotal);
  const col_boTotal = getColumnCode(bOAndCommittedTotalColumns.col_boTotal);
  const col_poTotal = getColumnCode(purchaseOrderColumn.col_poTotal);
  const col_ljlInventory = getColumnCode(vendorInventoryColumn.col_ljlInventory);
  const col_inbound = getColumnCode(inboundColumn.col_inbound);
  const colSet = {
    col_preferredVendor, col_activeKits,
    col_qohTotal, col_qohCa, col_availTotal, col_qohNC, col_availCA, col_availNC, col_mod, col_availPoModBo, col_refinish, col_scrDent,
    col_y3, col_y2, col_y1, col_y0, col_l12M, col_l3M, col_uniqueCustomerCount, col_topBuyerShare, col_topBuyerName, col_exclusions,
    col_commitTotal, col_boTotal,
    col_poTotal,
    col_ljlInventory,
    col_inbound,
  };

  const renderColumnCode = (col) => {
    return (
      (colSet[col]) ?
        <Column key={colSet[col].localColumnKey} dataField={colSet[col].dataField} caption={colSet[col].caption} allowEditing={colSet[col].allowEditing} format={colSet[col].format} dataType={colSet[col].dataType?? undefined} alignment={colSet[col].alignment?? undefined} width={colSet[col].width} cellRender={colSet[col].cellRender?? undefined} headerCellRender={colSet[col].headerCellRender?? undefined} calculateCellValue={colSet[col].calculateCellValue?? undefined} setCellValue={colSet[col].setCellValue?? undefined} cssClass={colSet[col].cssClass?? undefined} visible={colSet[col].visible?? true} >
          <HeaderFilter><Search enabled={true} /></HeaderFilter>
        </Column>
      : "")

  }

  if (error) return <div>Failed to load</div>;
  if (isLoading) {
    return (
      <div style={{ paddingTop: "5px" }}>
        <LinearProgress /> Loading...{" "}
      </div>
    );
  }
  
  const refreshGrid = () => {
    mutate();
  }

  return (
  <>
      <SumByColumn refSumByCol={refSumByCol} />
      <TreeList
        visible={!isLoading}
        height={"80vh"}
        ref={gridInstance}
        dataSource={data}
        showColumnLines={gridSettings.columnLines}
        showBorders={gridSettings.borders}
        showRowLines={gridSettings.rowLines}
        rowAlternationEnabled={gridSettings.rowAlternation}
        onNodesInitialized={onNodesInitialized}
        onContentReady={onContentReady}
        allowColumnResizing={true}
        columnResizingMode="widget"
        keyExpr="keyItem"
        parentIdExpr="ParentID"
        id="items"
        onCellPrepared={(e) => onCellPrepared(e, settings, categoriesList)}
        onContextMenuPreparing={addMenuItems}
        filterMode={"withAncestors"}
        autoExpandAll={true}
        allowColumnReordering={true}
        style={{ margin: "1vw", maxHeight: "80vh" }}
        onEditorPreparing={onEditorPreparing}
        wordWrapEnabled={false}
        onColumnsChange={(e) => console.log(e)}
        onEditingStart={onEditingStart}

      >
        <KeyboardNavigation
          editOnKeyPress={true}
          enterKeyAction={"moveFocus"}
          enterKeyDirection={"column"}
        />
        <StateStoring enabled={true} type="localStorage" storageKey={storageKey} />
        <SearchPanel visible={true} width={250} />
        <LoadPanel enabled={false} />
        <ColumnChooser
          enabled={true}
          mode={"select"}
          sortOrder={"asc"}
          height={"50vh"}
        >
          <Search enabled={true} />
        </ColumnChooser>
        <FilterRow visible={true} />
        <FilterPanel visible={true} />
        <HeaderFilter visible={true} />
        <ColumnFixing enabled={true} />
        <Selection mode="multiple" selectAllMode={"allPages"} />
        <Editing mode="cell" allowUpdating={true} />
        <Scrolling mode="virtual" rowRenderingMode="virtual" />
        <Toolbar>
          <Item location="before">
            <NetsuiteUpdateButton origin={`grid-${groupId}`} />
          </Item>
          {programId !== 0 && categoryId !== 0 ?
            <Item location="before">
              <ToolBarButton code="Settings" location="before" icon="preferences" action={showPreferences} tooltipText="Settings for the current Program and Category" />
            </Item>
            : ""
          }
          <Item location="before">
            <ToolBarDropDownButton code="Download Data" location="before" icon="exportxlsx" action={showExport} ExportOptions={ExportOptions} />
          </Item>
          <Item location="before">
            <ToolBarButton code="Delete Data" location="before" icon="clearformat" action={cleanUpdateFields} tooltipText="Clears user entered data for the current Program and Category." />
          </Item>
          <Item location="before">
            <ToolBarButton code="templates" location="before" icon="save" action={showSaveColumnConfiguration} tooltipText="Setup, share, or load a saved view/filter/template for the data grid." validatePermission={false} />
          </Item>
          <Item location="before">
            <MohSelectBox mohOptions={mohOptions} selectedModifier={selectedModifier} onSelectedMohChanged={(e) => recalculateValues(e.value)} />
          </Item>
          <Item location="before">
            <ToolBarButton code="filterSelected" location="before" icon="filter" action={filterBySelectedRows} tooltipText="filter by selected rows" validatePermission={false} />
          </Item>
          <Item location="before">
            <ToolBarButton code="refreshGrid" location="before" icon="refresh" action={refreshGrid} tooltipText="Refresh Grid" validatePermission={false} />
          </Item>
          <Item location="after">
            <ToolBarButton code="DataGridSumColumns" icon="datafield" action={showSumColumns} tooltipText="Sum of columns" validatePermission={false} />
          </Item>
          <Item location="after">
            <ToolBarButton code="DataGridLineSettings" icon="tableproperties" action={showGridSettings} tooltipText="Grid styles" validatePermission={false} />
          </Item>
          <Item
            name="columnChooserButton"
          />
          <Item
            name="searchPanel"
          />
        </Toolbar>
        {renderColumn("col_id", <Column caption="id" allowEditing={false} dataField="Id" dataType={"string"} headerCellRender={(data) => headerCellRender(data)} width={100} visible={false} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_name", <Column caption="Name" allowEditing={false} dataField="Name" dataType={"string"} headerCellRender={(data) => headerCellRender(data)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_program", <Column caption="Program" allowEditing={false} dataField="programId" dataType={"string"} headerCellRender={(data) => headerCellRender(data)} width={100} calculateCellValue={(rowData) => getProgramName(rowData, programsList)} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_category", <Column caption="Category" allowEditing={false} dataField="categoryId" dataType={"string"} headerCellRender={(data) => headerCellRender(data)} width={100} calculateCellValue={(rowData) => getCategoryName(rowData, categoriesList)} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_internalid", <Column icon="refresh" caption="Internal ID" allowEditing={false} dataField="InternalId" dataType={"string"} headerCellRender={(data) => headerCellRender(data)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_description", <Column dataField="Description" caption="Sales Description" allowEditing={false} dataType={"string"} headerCellRender={(data) => headerCellRender(data)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_purchaseDescription", <Column dataField="purchaseDescription" caption="Purchase Description" allowEditing={false} dataType={"string"} headerCellRender={(data) => headerCellRender(data)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_exclusivity", <Column caption="Exclusivity" allowEditing={false} dataField="Exclusivity" dataType={"string"} headerCellRender={(data) => headerCellRender(data)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_purchasePrice", <Column dataField="Purchase Price" caption="Purchase Price" allowEditing={false} dataType={"number"} format={'$#,##0.##'} editorOptions={saleAmountEditorOptions} headerCellRender={(data) => headerCellRender(data)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumnCode('col_preferredVendor')}
        {renderColumn("col_universalProductType", <Column dataField="universalProductType" caption="Universal Product Type" allowEditing={false} headerCellRender={(data) => headerCellRender(data)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_groupNotes", <Column dataField="Group Notes" allowEditing={false} dataType={"string"} headerCellRender={(data) => headerCellRender(data)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_inactive", <Column dataField="Inactive" allowEditing={false} dataType={"string"} headerCellRender={(data) => headerCellRender(data)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_discontinued1", <Column dataField="Discontinued 1" allowEditing={false} dataType={"string"} headerCellRender={(data) => headerCellRender(data)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_replacedBy", <Column dataField="replacedBy" caption="Replaced By" allowEditing={false} dataType={"string"} headerCellRender={(data) => headerCellRender(data)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_replaces", <Column dataField="replaces" caption="Replaces" allowEditing={false} dataType={"string"} headerCellRender={(data) => headerCellRender(data)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumnCode('col_qohTotal')}
        {renderColumnCode('col_qohCa')}
        {renderColumnCode('col_qohNC')}
        {renderColumnCode('col_availTotal')}
        {renderColumnCode('col_availCA')}
        {renderColumnCode('col_availNC')}
        {renderColumnCode('col_poTotal')}
        {renderColumnCode('col_commitTotal')}
        {renderColumnCode('col_boTotal')}
        {renderColumnCode('col_mod')}
        {renderColumnCode('col_availPoModBo')}
        {renderColumnCode('col_inbound')}
        {renderColumnCode('col_refinish')}
        {renderColumnCode('col_scrDent')}
        {renderColumnCode('col_ljlInventory')}
        {renderColumnCode('col_y3')}
        {renderColumnCode('col_y2')}
        {renderColumnCode('col_y1')}
        {renderColumnCode('col_y0')}
        {renderColumn("col_yTotal", <Column dataField="Y_Total" caption={"STLY History"} allowEditing={false} cellRender={renderYoyCell} alignment="center" dataType={"string"} headerCellRender={(data) => headerCellRender(data)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_yoyStability", <Column dataField="YoY_Stability" caption="YOY Stability" cellRender={renderYoyStabilityCell} allowEditing={false} alignment="center" dataType={"string"} headerCellRender={(data) => headerCellRender(data)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_y2_to_y1", <Column dataField="y2ToY1" caption="Y2 To Y1" alignment="right" calculateCellValue={calculateY2ToY1} cellRender={rendery2y1} allowEditing={false} dataType={"number"} headerCellRender={(data) => headerCellRender(data)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumnCode('col_l12M')}
        {renderColumnCode('col_l3M')}
        {renderColumn("col_l12MAvg", <Column dataField="L12MAvg" caption="L12M Avg" allowEditing={false} dataType={"number"} headerCellRender={(data) => headerCellRender(data)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_l3MAvgM", <Column dataField="L3M Avg" caption="L3M Avg" allowEditing={false} dataType={"number"} headerCellRender={(data) => headerCellRender(data)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_l3MvsL12M", <Column dataField="L3M vs L12M" caption="L3M vs L12M" allowEditing={false} cellRender={renderL3MvsL12M} dataType={"number"} headerCellRender={(data) => headerCellRender(data)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumnCode('col_activeKits')}
        {renderColumn("col_activePublic", <Column dataField="ActivePublic" caption="Public Systems" allowEditing={false} dataType={"number"} headerCellRender={(data) => headerCellRender(data)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_activeNoPublic", <Column dataField="ActiveNoPublic" caption="Non-Public Systems" allowEditing={false} dataType={"number"} headerCellRender={(data) => headerCellRender(data)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumnCode('col_uniqueCustomerCount')}
        {renderColumnCode('col_topBuyerShare')}
        {renderColumnCode('col_topBuyerName')}
        {renderColumn("col_minOrderQuantity", <Column dataField="minOrderQuantity" caption="Min Order Quantity" allowEditing={false} dataType={"number"} headerCellRender={(data) => headerCellRender(data)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_maxOrderQuantity", <Column dataField="maxOrderQuantity" caption="Max Order Quantity" allowEditing={false} dataType={"number"} headerCellRender={(data) => headerCellRender(data)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_reorderMultiple", <Column dataField="reorderMultiple" caption="Reorder Multiple" allowEditing={false} dataType={"number"} headerCellRender={(data) => headerCellRender(data)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_lowMoh", <Column dataField="Low MoH" caption="Low Est Qty Defecit" allowEditing={false} dataType={"number"} headerCellRender={(data) => headerCellRender(data)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_midMoh", <Column dataField="Mid MoH" caption="Mid Est Qty Defecit" allowEditing={false} dataType={"number"} headerCellRender={(data) => headerCellRender(data)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_targetMoh", <Column dataField="Target MoH" caption="Target MOH Qty Defecit" allowEditing={false} dataType={"number"} headerCellRender={(data) => headerCellRender(data)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_suggestedPoQty", <Column dataField="Suggested PO Qty" allowEditing={false} dataType={"number"} headerCellRender={(data) => headerCellRender(data)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_costSuggested", <Column dataField="CostSuggested" caption="Suggested PO Cost" allowEditing={false} dataType={"number"} format={'$#,##0.##'} editorOptions={saleAmountEditorOptions} headerCellRender={(data) => headerCellRender(data)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_poQtyReviewer", <Column dataField="poQtyReviewer" dataType="number" caption="PO Qty Reviewer" cellRender={(data) => renderIconQtyCell(data, "Suggested PO Qty")} allowEditing={isColEditable("col_poQtyReviewer")} setCellValue={async (newData, value, currentRowData) => await setCellValueAndCost(newData, value, currentRowData, "poQtyReviewer", "poQtyReviewer", "I", user.id, "CostReviewer")} headerCellRender={(data) => headerCellRender(data)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_poNotesReviewer", <Column dataField="poNotesReviewer" dataType={"string"} caption="PO Notes Reviewer" allowEditing={isColEditable("col_poNotesReviewer")} setCellValue={async (newData, value, currentRowData) => await setCellValue(newData, value, currentRowData, "poNotesReviewer", "poNotesReviewer", "V", user.id)} headerCellRender={(data) => headerCellRender(data)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_costReviewer", <Column dataField="CostReviewer" caption="Reviewer PO Cost" allowEditing={false} calculateCellValue={calculatePoReviewerCost} format={'$#,##0.##'} editorOptions={saleAmountEditorOptions} headerCellRender={(data) => headerCellRender(data)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_poQtyMarket", <Column dataField="poQtyMarket" dataType="number" caption="PO Qty Market" cellRender={(data) => renderIconQtyCell(data, "Suggested PO Qty")} allowEditing={isColEditable("col_poQtyMarket")} setCellValue={async (newData, value, currentRowData) => await setCellValueAndCost(newData, value, currentRowData, "poQtyMarket", "poQtyMarket", "I", user.id, "CostMarket")} headerCellRender={(data) => headerCellRender(data)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_poNotesMarket", <Column dataField="poNotesMarket" dataType={"string"} caption="PO Notes Market" allowEditing={isColEditable("col_poNotesMarket")} setCellValue={async (newData, value, currentRowData) => await setCellValue(newData, value, currentRowData, "poNotesMarket", "poNotesMarket", "V", user.id)} headerCellRender={(data) => headerCellRender(data)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_costMarket", <Column dataField="CostMarket" dataType="number" caption="Market PO Cost" allowEditing={false} calculateCellValue={calculatePoCostMarket} format={'$#,##0.##'} editorOptions={saleAmountEditorOptions} headerCellRender={(data) => headerCellRender(data)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_poQtyApprover", <Column dataField="poQtyApprover" dataType="number" caption="PO Qty Approver" cellRender={(data) => renderIconQtyCell(data, "Suggested PO Qty")} allowEditing={isColEditable("col_poQtyApprover")} setCellValue={async (newData, value, currentRowData) => await setCellValueAndCost(newData, value, currentRowData, "poQtyApprover", "poQtyApprover", "I", user.id, "CostApprover")} headerCellRender={(data) => headerCellRender(data)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_poNotesApprover", <Column dataField="poNotesApprover" dataType={"string"} caption="PO Notes Approver" allowEditing={isColEditable("col_poNotesApprover")} setCellValue={async (newData, value, currentRowData) => await setCellValue(newData, value, currentRowData, "poNotesApprover", "poNotesApprover", "V", user.id)} headerCellRender={(data) => headerCellRender(data)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_costApprover", <Column dataField="CostApprover" dataType="number" caption="Approver PO Cost" allowEditing={false} calculateCellValue={calculatePoCostApprover} format={'$#,##0.##'} editorOptions={saleAmountEditorOptions} headerCellRender={(data) => headerCellRender(data)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_additionalMohL3ML12MLoad", <Column dataField="additionalMohL3ML12MLoad" caption="Additional MoH Load (L12M | L3M)" allowEditing={false} calculateCellValue={(rowData) => CalculateAdditionalMoHLoad(rowData, selectedModifier)} headerCellRender={(data) => headerCellRender(data)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_remainingEstMoh12M", <Column dataType="number" dataField="Remaining Est MoH L12M" caption="Remaining Est MoH L12M" allowEditing={false} calculateCellValue={(rowData) => calculateRemainingEstMoh(rowData, 12)} headerCellRender={(data) => headerCellRender(data)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_projectedMohAfterPoL12M", <Column dataType="number" dataField="Projected MoH after PO L12M" caption="Projected MoH after PO L12M" allowEditing={false} cellRender={(data) => renderProjectedMoHAfterL12M(data, settings)} headerCellRender={(data) => headerCellRender(data)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_remainingEstMoh3M", <Column dataType="number" dataField="Remaining Est MoH L3M" caption="Remaining Est MoH L3M" allowEditing={false} calculateCellValue={(rowData) => calculateRemainingEstMoh(rowData, 3)} headerCellRender={(data) => headerCellRender(data)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_projectedMohAfterPoL3M", <Column dataType="number" dataField="Projected MoH after PO L3M" caption="Projected MoH after PO L3M" allowEditing={false} cellRender={(data) => renderProjectedMoHAfterL3M(data, settings)} headerCellRender={(data) => headerCellRender(data)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_revisedPoQty", <Column dataField="Revised PO Qty" caption="Revised PO Qty" allowEditing={false} calculateCellValue={(rowData) => calculateRevisedPoQty(rowData, selectedModifier)} headerCellRender={(data) => headerCellRender(data)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_updatedDate", <Column caption="Updated Date" allowEditing={false} dataField="UpdatedDate" format={"yyyy-MM-dd hh:mm:ss"} dataType={"date"} headerCellRender={(data) => headerCellRender(data)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_createdDate", <Column dataField="CreatedDate" caption="Creation Date" allowEditing={false} format={"yyyy-MM-dd hh:mm:ss"} dataType={"date"} headerCellRender={(data) => headerCellRender(data)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumn("col_itemAge", <Column caption="Age (Months)" allowEditing={false} dataField="Age" dataType={"number"} headerCellRender={(data) => headerCellRender(data)} width={100} ><HeaderFilter ><Search enabled={true} /></HeaderFilter></Column>)}
        {renderColumnCode('col_exclusions')}
      </TreeList>
      <GridTooltips columns={columns} />
      {LogModalRender}
      {ExportModalRender}
      {GridSettingsModalRender}
      {SaveGridTemplateDialogRender}
      {SumColumnsModalRender}
      {SettingsModalRender}
      {PreferredVendorModalRender}
      {ActiveKitsModalRender}
      {InventoryModalRender}
      {UniqueCustomerModalRender}
      {BackModalRender}
      {PurchaseOrderModalRender}
      {VendorInventoryModalRender}
      {ConsolidateModalRender}
    </>
  );
};

export default MainGrid;

